import * as React from "react"

import NotFound from './404'


const IndexPage = () => {
  return <NotFound />
}

export default IndexPage
